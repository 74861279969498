:root { 
--gray_900_02:#202020; 
--blue_gray_100:#d9d9d9; 
--gray_500:#a2a2a2; 
--blue_gray_100_33:#d9d9d933; 
--gray_900:#252525; 
--gray_900_01:#1f1f1f; 
--light_green_300:#9cee8c; 
--green_A200:#60d3aa; 
--white_A700_dd:#ffffffdd; 
--green_A200_dd:#60d3aadd; 
--cyan_400_dd:#1fbabfdd; 
--blue_gray_900_01:#353535; 
--blue_gray_900_cc:#2b2b2bcc; 
--blue_gray_900:#303030; 
--white_A700:#ffffff; 
--cyan_400:#1fbabf; 
}