#button {
    display: inline-block;
    background-color: rgb(96, 211, 170);
    width: 50px;
    height: 50px;
    text-align: center;
    border-radius: 4px;
    position: fixed;
    bottom: 30px;
    right: 30px;
    transition: background-color .3s, 
      opacity .5s, visibility .5s;
    opacity: 0;
    visibility: hidden;
    z-index: 1000;
  }
  #button:hover {
    cursor: pointer;
    background-color: #333;
  }
  #button:active {
    background-color: #555;
  }
  #button.show {
    opacity: 1;
    visibility: visible;
  }
  